import NewsCard from '../NewsCard';
import Button from '../Button';
import classNames from 'classnames';
import styles from './NewsCardList.module.scss';

const NewsCardList = ({ title, link, items }) => {
    return (
        <div className={styles['NewsCardList']}>
            <div className={styles['NewsCardList__Container']}>
                <div className={styles['NewsCardList__Row']}>
                    <h2 className={styles['NewsCardList__Title']}>{title}</h2>
                    {link && (
                        <Button link={link} type={'tertiary'} icon={'arrow'} />
                    )}
                </div>

                {items && (
                    <div className={styles['NewsCardList__List']}>
                        {items.map((item, i) => (
                            <div
                                key={i}
                                className={classNames(
                                    styles['NewsCardList__Item'],
                                    styles[`NewsCardList__Item--${i + 1}`] // Adjusted index to match the counter
                                )}>
                                <NewsCard {...item} index={i + 1} /> {/* Adjusted index */}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

NewsCardList.propTypes = {};

NewsCardList.defaultProps = {};

export default NewsCardList;
