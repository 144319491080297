// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import styles from './NewsCard.module.scss';

import Image from '../Image';

const NewsCard = ({ title, preamble, link, image, publishedDate, index }) => {
    return (
        <a
            href={link.href}
            className={classNames(
                styles['NewsCard'],
                styles[`NewsCard--${index}`]
            )}>
            <div className={styles['NewsCard__ImageWrap']}>
                {image && image.url && (
                    <Image
                        {...image}
                        layout="fill"
                        objectFit="cover"
                        priority={true}
                        onLoadingComplete={() => sethasLoadedImage(true)}
                    />
                )}
            </div>

            <div className={styles['NewsCard__Content']}>
                <div className={styles['NewsCard__Top']}>
                    <h3 className={styles['NewsCard__Title']}>{title}</h3>
                    <p className={styles['NewsCard__Preamble']}>{preamble}</p>
                </div>
                <div className={styles['NewsCard__Bottom']}>
                    <span className={styles['NewsCard__Date']}>{publishedDate}</span>
                    <div className={styles['NewsCard__Arrow']}>
                        <Icon type="arrow" size="large" />
                    </div>
                </div>
            </div>
        </a>
    );
};

NewsCard.propTypes = {};

NewsCard.defaultProps = {
    link: {},
    image: {},
    index: 1,
};

export default NewsCard;
